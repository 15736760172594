import { CampaignTargetType } from 'types/Campaign';
import type { ContactActionType } from 'types/Contact';

export enum IntegrationType {
  ATS = 'ats',
  CRM = 'crm',
  ENRICHMENT = 'enrich',
}

export enum IntegrationStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum IntegrationCRMContactType {
  CONTACT = 'CONTACT',
  LEAD = 'LEAD',
}

export enum CrelateCRMMappingType {
  CANDIDATE = 1,
  CONTACT = 2,
  LEAD = 4,
}

export interface Rule {
  id: string;
  label: string;
  description: string;
  infoText?: string;
  hasChannelOptions: boolean;
}

export interface IntegrationEventMapping {
  eventName: Omit<
    ContactActionType,
    ContactActionType.CONNECTED | ContactActionType.OPENED_EMAIL
  >;
  mapping: string;
}

export const DEFAULT_ACTIVE_CAMPAIGN_TAG = 'inTalinCampaign';

export const IntegrationRules: Record<string, Rule> = {
  ACTIVE_CAMPAIGN_TAG: {
    id: 'activeCampaignTag',
    label: 'Add tag when added to active campaign',
    description:
      'Mark candidates in ATS with following tag when in an Active campaign:',
    hasChannelOptions: false,
  },
  ATTACH_MESSAGE_COPY: {
    id: 'attachMessageCopy',
    label: 'Attach message copy to notes',
    description: 'Include message and reply content in notes',
    hasChannelOptions: false,
  },
  PULL_EMAILS: {
    id: 'pullEmails',
    label: 'Sync emails',
    description:
      'Pull candidate emails that already exist in your ATS into Talin',
    hasChannelOptions: false,
  },
  PUSH_ON_ENRICH: {
    id: 'pushOnEnrich',
    label: 'Update when enriched',
    description: `their email or phone number is enriched`,
    hasChannelOptions: false,
  },
  PUSH_ON_UPLOAD: {
    id: 'pushOnUpload',
    label: 'Upload contacts',
    description: 'they are uploaded to Talin',
    hasChannelOptions: false,
  },
  RECORD_REPLIES: {
    id: 'recordReplies',
    label: 'Record replied events',
    description: 'they reply to a message sent from Talin via',
    hasChannelOptions: true,
  },
  RECORD_SENDS: {
    id: 'recordSends',
    label: 'Record messages sent',
    description: 'they receive a message from Talin via',
    hasChannelOptions: true,
  },
  USE_INDIVIDUAL_USERS: {
    id: 'useIndividualUsers',
    label: 'Use individual users',
    description: 'Add notes and create candidates as individual users',
    infoText: `If enabled, actions in your ATS will be performed by users that correspond to Talin members based on a matching email address. If disabled, actions will be performed by a dedicated integration user (or whoever connected the integration).`,
    hasChannelOptions: false,
  },
  USE_CRELATE_AS_CRM: {
    id: 'useCrelateAsCrm',
    label: 'Use Crelate as CRM',
    description: 'If using Crelate as your CRM, sync prospects as ',
    hasChannelOptions: false,
  },

  // Enrichment rules
  ENRICH_CONTACTS_WITH_PROVIDER: {
    id: 'enrichContactsWithProvider',
    label: 'Enrich contacts with provider',
    description: '',
    hasChannelOptions: false,
  },
};

export enum IntegrationRuleMessageChannel {
  'EMAIL' = 'EMAIL',
  'LINKEDIN' = 'LINKEDIN',
}

export enum IntegrationRuleEnrichmentOrder {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  INSTEAD = 'INSTEAD',
}

export enum IntegrationVendorName {
  CRELATE = 'crelate',
  RECRUITCRM = 'recruitcrm',
  BULLHORN = 'bullhorn',
  LOXO = 'loxo',
  HUBSPOT = 'hubspot',
  LEVER = 'lever',
}

export const INTEGRATIONS_WITH_JOB_MAPPING = [
  IntegrationVendorName.BULLHORN,
  IntegrationVendorName.CRELATE,
  IntegrationVendorName.HUBSPOT,
  IntegrationVendorName.LOXO,
  IntegrationVendorName.RECRUITCRM,
];

export const INTEGRATIONS_WITH_ACTIVE_CAMPAIGN_NOTES_INSTEAD_OF_TAGS = [
  IntegrationVendorName.BULLHORN,
  IntegrationVendorName.LOXO,
  IntegrationVendorName.RECRUITCRM,
];

export interface IntegrationRuleSetting {
  isEnabled: boolean;

  channels?: IntegrationRuleMessageChannel[];
  contactType?: IntegrationCRMContactType;
  tagName?: string;
  enrichmentOrder?: IntegrationRuleEnrichmentOrder;
}

interface IntegrationSettings {
  eventMappings?: IntegrationEventMapping[];
  crmContactType?: IntegrationCRMContactType;
}

export interface Integration {
  integrationId: number;
  customerId: number;
  externalConnectionId: string;
  vendor: IntegrationVendorName;
  type: IntegrationType;
  status: IntegrationStatus;
  connectedByUserId: number;
  rules: Record<Rule['id'], IntegrationRuleSetting> | null;
  logoUrl?: string;
  label?: string;
  dateCreated: Date;
  dateUpdated: Date;
  settings?: IntegrationSettings;
}

export type NewIntegration = Omit<
  Integration,
  'integrationId' | 'customerId' | 'status' | 'dateCreated' | 'dateUpdated'
>;

export type IntegrationUpdateValues = Partial<
  Pick<
    Integration,
    'externalConnectionId' | 'vendor' | 'status' | 'rules' | 'settings'
  >
>;
export type MappingSettings = {
  [K in CampaignTargetType]: IntegrationType;
};

export const DEFAULT_MAPPING_SETTINGS: MappingSettings = {
  [CampaignTargetType.CANDIDATE]: IntegrationType.ATS,
  [CampaignTargetType.PROSPECT]: IntegrationType.CRM,
};
