export interface EmailCredentials {
  accessToken: string;
}

export enum UserEmailAccountType {
  EMAIL = 'EMAIL',
  LINKEDIN_SYNC = 'LINKEDIN_SYNC',
}

export interface UserEmailAccountFilters {
  emailAccountId?: string;
  emails?: string[];
  statuses?: UserEmailAccountStatus[];
  type?: UserEmailAccountType;
}

export interface UserEmailAccount {
  userEmailAccountId: number;
  customerId: number;
  userId: number;
  type: UserEmailAccountType;
  email: string;
  emailAccountId: string;
  credentials: EmailCredentials;
  status: UserEmailAccountStatus;
}

export type NewUserEmailAccount = Omit<
  UserEmailAccount,
  'userEmailAccountId' | 'customerId' | 'status'
>;

export enum UserEmailAccountStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  DEPRECATED = 'DEPRECATED',
}
