import type { ChatPostMessageArguments } from '@slack/web-api';
import Stripe from 'stripe';
import type { NewCampaign, BuilderStep } from '@/components/Campaigns/types';
import { poll } from '@/utils/helpers';
import type {
  AsyncTask,
  AsyncTaskFilter,
  GetAsyncTaskListReturn,
} from 'types/AsyncTask';
import { AsyncTaskStatus, COMPLETED_TASK_STATUSES } from 'types/AsyncTask';
import type { ImportContactsTaskType } from 'types/Automation';
import { LinkedinStatus } from 'types/Automation';
import type {
  Campaign,
  CampaignContactStateFilter,
  CampaignFilter,
  CampaignType,
  CampaignWithSequence,
  GetCampaignListReturn,
  GetContactsByCampaignReturn,
} from 'types/Campaign';
import type { CampaignContactState } from 'types/Campaign';
import type {
  Contact,
  ContactFilter,
  DisplayableContactAction,
  NewContact,
} from 'types/Contact';
import type { EnrichmentLevel, EnrichmentType } from 'types/ContactEnrichment';
import type {
  Conversation,
  ConversationFilters,
  DisplayableConversation,
} from 'types/Conversation';
import type { Customer, DuplicateContactRules } from 'types/Customer';
import type { EventMetadata, EventType } from 'types/Event';
import type {
  ExternalJob,
  Integration,
  IntegrationCRMContactType,
  IntegrationStatus,
  IntegrationType,
  IntegrationUpdateValues,
} from 'types/Integration';
import type { ManualTask, ManualTaskFilter } from 'types/ManualTask';
import type { GetListMessage, MessageFilters, NewMessage } from 'types/Message';
import type { Paging, PagingWithCursor } from 'types/Paging';
import type {
  PersonalizedMessage,
  PersonalizedMessageFilter,
  PersonalizedMessageStatus,
} from 'types/PersonalizedMessage';
import type { TemplateWithSteps } from 'types/Template';
import type { User, UserListQuery, UserRole, UserStatus } from 'types/User';
import { axiosWithToken } from './utils/axiosWithToken';

const stripe = new Stripe(process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY as string);

export const getCustomer = async ({
  customerId,
}: {
  customerId: number | 'current';
}): Promise<Customer | null> => {
  const response = await axiosWithToken.get(`/api/customers/${customerId}`);
  const { customer } = response.data;
  return customer || null;
};

/** file://./../pages/api/customers/[customerId].ts */
export const updateCustomer = async ({
  customerId,
  ...updateValues
}: {
  customerId: number | 'current';
} & Partial<Omit<Customer, 'customerId'>>): Promise<void> => {
  await axiosWithToken.put(`/api/customers/${customerId}`, updateValues);
};

export interface NewUserArgs {
  customerId: number;
  name: string;
  email: string;
  role: UserRole;
  status: UserStatus;
  invitedByUserId?: number;
}

/** file://./../pages/api/users.ts */
export const createUser = async ({
  user,
}: {
  user: NewUserArgs;
}): Promise<number> => {
  const { data } = await axiosWithToken.post(`/api/users`, {
    ...user,
    linkedinStatus: 'NEW',
  });
  return data.userId;
};

/** file://./../pages/api/users/[userId].ts */
export const getUser = async ({
  userId,
}: {
  userId: number;
}): Promise<User | null> => {
  const response = await axiosWithToken.get(`/api/users/${userId}`);
  const { user } = response.data;

  if (!user) {
    return null;
  }

  return user;
};

/** file://./../pages/api/users.ts */
export const getUserList = async (params: UserListQuery): Promise<User[]> => {
  const response = await axiosWithToken.get(`/api/users`, { params });
  const { users } = response.data;
  return users;
};

/** file://./../pages/api/users.ts */
export const getCurrentUser = async (): Promise<User | null> => {
  const response = await axiosWithToken.get(`/api/users?current=true`);
  const { user } = response.data;

  if (!user) {
    return null;
  }

  return user;
};

export const attemptActivation = async (data: {
  authId: string;
  name?: string;
}): Promise<User> => {
  const response = await axiosWithToken.put(`/api/users/activate`, data);
  const { user } = response.data;
  return user;
};

export const getOrCreateUser = async ({
  authId,
  name,
}: {
  authId: string;
  name?: string;
}): Promise<User | null> => {
  try {
    const existingUser = await getCurrentUser();
    if (existingUser) {
      return existingUser;
    }

    const activationResult = await attemptActivation({ authId, name });
    if (activationResult) {
      await updateUserAgent({ userId: activationResult.userId });
    }
    return activationResult;
  } catch (error) {
    return null; // null user returned only on error
  }
};

/** file://./../pages/api/users/[userId].ts */
export const updateUser = async ({
  userId,
  ...updateValues
}: {
  userId: number;
} & Partial<Omit<User, 'userId'>>): Promise<void> => {
  await axiosWithToken.put(`/api/users/${userId}`, {
    ...updateValues,
  });
};

export const updateUserAgent = async ({
  userId,
}: {
  userId: number;
}): Promise<void> => {
  await axiosWithToken.put(`/api/users/${userId}/updateUserAgent`);
};

/** file://./../pages/api/integrations.ts */
export const createIntegration = async ({
  connectionId,
  crmContactType,
  type,
}: {
  connectionId: string;
  crmContactType?: IntegrationCRMContactType;
  type: IntegrationType;
}): Promise<void> => {
  await axiosWithToken.post(`/api/integrations`, {
    connectionId,
    crmContactType,
    type,
  });
};

export const getIntegrationList = async (
  params: {
    status?: IntegrationStatus;
    type?: IntegrationType;
  } = {}
): Promise<Integration[]> => {
  const response = await axiosWithToken.get(`/api/integrations`, { params });
  return response.data.integrations;
};

export const getIntegration = async ({
  integrationId,
}: {
  integrationId: number;
}): Promise<Integration> => {
  const response = await axiosWithToken.get(
    `/api/integrations/${integrationId}`
  );
  return response.data.integration;
};

export const updateIntegration = async ({
  integrationId,
  ...updateValues
}: {
  integrationId: number;
} & IntegrationUpdateValues): Promise<void> => {
  await axiosWithToken.patch(`/api/integrations/${integrationId}`, {
    ...updateValues,
  });
};

/** file://./../pages/api/campaigns.ts */
export const createCampaign = async ({
  campaign,
}: {
  campaign: NewCampaign;
}): Promise<number> => {
  const { data } = await axiosWithToken.post(`/api/campaigns`, {
    ...campaign,
  });
  const { campaignId } = data;
  return campaignId;
};

/** file://./../pages/api/campaigns/[campaignId].ts */
export const getCampaign = async ({
  campaignId,
  contactId,
}: {
  campaignId: number;
  contactId?: number;
}): Promise<CampaignWithSequence> => {
  const url = contactId
    ? `/api/campaigns/${campaignId}?contactId=${contactId}`
    : `/api/campaigns/${campaignId}`;

  const response = await axiosWithToken.get(url);
  const { campaign } = response.data;
  return campaign;
};

/** file://./../pages/api/campaigns.ts */
export const getCampaignList = async ({
  filters,
  paging,
  includeStats,
}: {
  filters?: CampaignFilter;
  paging?: Paging;
  includeStats?: boolean;
}): Promise<GetCampaignListReturn> => {
  const response = await axiosWithToken.get(`/api/campaigns`, {
    params: { filters, paging, includeStats },
  });

  return response.data;
};

/** file://./../pages/api/admincampaigns.ts */
export const getAdminCampaignList = async ({
  filters,
  paging,
}: {
  filters?: CampaignFilter;
  paging?: Paging;
}): Promise<GetCampaignListReturn> => {
  const response = await axiosWithToken.get(`/api/admincampaigns`, {
    params: { filters, paging },
  });
  return response.data;
};

export interface CampaignUpdateValues extends Campaign {
  steps: BuilderStep[];
}

/** file://./../pages/api/campaigns/[campaignId].ts */
export const updateCampaign = async ({
  campaignId,
  contactId = null,
  ...campaignUpdateValues
}: {
  campaignId: number;
  contactId?: number | null;
} & Partial<CampaignUpdateValues>): Promise<void> => {
  const url = contactId
    ? `/api/campaigns/${campaignId}?contactId=${contactId}`
    : `/api/campaigns/${campaignId}`;

  await axiosWithToken.put(url, { ...campaignUpdateValues });
};

export const deleteCampaign = async ({
  campaignId,
}: {
  campaignId: number;
}): Promise<boolean> => {
  try {
    await axiosWithToken.delete(`/api/campaigns/${campaignId}`);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/** file://./../pages/api/campaigns/[campaignId]/contacts.ts */
export const getCampaignContactList = async ({
  campaignId,
  filters,
  paging,
}: {
  campaignId: number;
  filters?: CampaignContactStateFilter;
  paging?: Paging;
}): Promise<GetContactsByCampaignReturn> => {
  const response = await axiosWithToken.get(
    `/api/campaigns/${campaignId}/contacts`,
    { params: { paging, filters } }
  );
  return response.data;
};

/** file://./../pages/api/campaigns/[campaignId]/contacts.ts */
export const deleteCampaignContacts = async ({
  campaignId,
  contactIds,
}: {
  campaignId: number;
  contactIds: number[];
}): Promise<boolean> => {
  try {
    await axiosWithToken.patch(`/api/campaigns/${campaignId}/contacts`, {
      contactIds,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/** file://./../pages/api/campaigns/[campaignId]/contacts.ts */
export const createContacts = async ({
  campaignId,
  contacts,
  importRules,
}: {
  campaignId: number;
  contacts: NewContact[];
  importRules?: DuplicateContactRules;
}): Promise<{
  numberOfContactsAdded: number;
  numberOfInvalidEmails: number;
}> => {
  const response = await axiosWithToken.post(
    `/api/campaigns/${campaignId}/contacts`,
    { contacts, importRules }
  );
  return response.data;
};

export const updateCampaignContact = async ({
  campaignId,
  campaignContactStateId,
  ...contactUpdateValues
}: {
  campaignId: number;
  campaignContactStateId: number;
} & Partial<CampaignContactState>): Promise<void> => {
  await axiosWithToken.put(
    `/api/campaigns/${campaignId}/contacts/${campaignContactStateId}`,
    { ...contactUpdateValues }
  );
};

/** file://./../pages/api/contacts.ts */
export const getContactList = async ({
  filters,
}: {
  filters?: ContactFilter;
}): Promise<{ contacts: Contact[] }> => {
  const response = await axiosWithToken.get(`/api/contacts`, {
    params: { filters },
  });

  return response.data;
};

/** file://./../pages/api/contacts/[contactId].ts */
export const updateContact = async ({
  contactId,
  ...contactUpdateValues
}: {
  contactId: number;
} & Partial<Contact>): Promise<void> => {
  await axiosWithToken.put(`/api/contacts/${contactId}`, {
    ...contactUpdateValues,
  });
};

/** file://./../pages/api/contacts/[contactId]/contactActions.ts */
export const getContactActionList = async ({
  contactId,
}: {
  contactId: number;
}): Promise<DisplayableContactAction[]> => {
  const response = await axiosWithToken.get(
    `/api/contacts/${contactId}/contactActions`,
    {}
  );

  return response.data;
};

/** file://./../pages/api/conversations/[conversationId].ts */
export const getConversation = async ({
  conversationId,
}: {
  conversationId: number;
}): Promise<DisplayableConversation> => {
  const response = await axiosWithToken.get(
    `/api/conversations/${conversationId}`
  );

  return response.data.conversation;
};

/** file://./../pages/api/conversations.ts */
export const getConversationList = async ({
  filters,
  paging,
}: {
  filters?: ConversationFilters;
  paging?: Paging | PagingWithCursor;
}): Promise<{ conversations: DisplayableConversation; paging: Paging }> => {
  const response = await axiosWithToken.get(`/api/conversations`, {
    params: { filters, paging },
  });

  return response.data;
};

/** file://./../pages/api/conversations/[conversationId].ts */
export const updateConversation = async ({
  conversationId,
  ...updateValues
}: {
  conversationId: number;
} & Partial<Conversation>): Promise<void> => {
  await axiosWithToken.put(
    `/api/conversations/${conversationId}`,
    updateValues
  );
};

/** file://./../pages/api/messages.ts */
export const createMessage = async ({
  message,
}: {
  message: Pick<NewMessage, 'contactId' | 'channel' | 'content'>;
}): Promise<void> => {
  await axiosWithToken.post(`/api/messages`, message);
};

/** file://./../pages/api/messages.ts */
export const getMessageList = async ({
  filters,
  paging,
}: {
  filters?: MessageFilters;
  paging?: Paging | PagingWithCursor;
}): Promise<GetListMessage> => {
  const response = await axiosWithToken.get(`/api/messages`, {
    params: { filters, paging },
  });
  return response.data;
};

export const createTemplate = async (template: {
  name: string;
  sequence: BuilderStep[];
  type: CampaignType;
}) => {
  const { data } = await axiosWithToken.post(`/api/templates`, {
    ...template,
  });

  const { templateId } = data;

  return templateId;
};

export const getTemplateList = async (params: {
  type?: CampaignType;
}): Promise<TemplateWithSteps[]> => {
  const response = await axiosWithToken.get(`/api/templates`, { params });
  const { templates } = response.data;
  return templates;
};

export const updateAsyncTask = async ({
  asyncTaskId,
  ...updateValues
}: {
  asyncTaskId: number;
} & Partial<AsyncTask>) => {
  const response = await axiosWithToken.put(`/api/asyncTasks/${asyncTaskId}`, {
    ...updateValues,
  });
  return response.data;
};

export const getAsyncTask = async ({
  asyncTaskId,
}: {
  asyncTaskId: number;
}): Promise<AsyncTask> => {
  const response = await axiosWithToken.get(`/api/asyncTasks/${asyncTaskId}`);

  const { asyncTask } = response.data;

  return asyncTask;
};

/** file://./../pages/api/asyncTasks.ts */
export const getAsyncTaskList = async (params: {
  paging?: Paging;
  filters?: AsyncTaskFilter;
}): Promise<GetAsyncTaskListReturn> => {
  const response = await axiosWithToken.get(`/api/asyncTasks`, { params });

  return response.data;
};

export const pollForAsyncTaskComplete = async ({
  asyncTaskId,
}: {
  asyncTaskId: number;
}) => {
  const validate = (asyncTask: AsyncTask) =>
    !COMPLETED_TASK_STATUSES.includes(asyncTask?.status ?? '');

  const asyncTask = await poll(
    () => getAsyncTask({ asyncTaskId }),
    validate,
    5000
  );

  return asyncTask;
};

export const refreshReplyData = async () => {
  const { data } = await axiosWithToken.post(`api/automation/getReplyData`);

  const { asyncTaskId } = data;

  if (!asyncTaskId) {
    throw new Error('Failed to create task to get reply data');
  }

  const asyncTask = await pollForAsyncTaskComplete({ asyncTaskId });

  if (asyncTask.status === AsyncTaskStatus.ERROR) {
    throw new Error(asyncTask.taskData.errorMessage);
  }
};

/** file://./../pages/api/customerSmsAccounts.ts */
export const createCustomerSmsAccount = async () => {
  const response = await axiosWithToken.post(`/api/customerSmsAccounts`);
  return response.data;
};

/** file://./../pages/api/customerSmsAccounts.ts */
export const getCustomerSmsAccount = async () => {
  const response = await axiosWithToken.get(`/api/customerSmsAccounts`);
  return response.data;
};

/** file://./../pages/api/phoneNumbers.ts */
export const createPhoneNumber = async () => {
  await axiosWithToken.post(`/api/phoneNumbers`);
};

export const connectAccount = async ({
  userId,
  email,
  password,
}: {
  userId: number;
  email: string;
  password: string;
}) => {
  const { data } = await axiosWithToken.post(`/connectAccount`, {
    userId,
    email,
    password,
  });

  const { asyncTaskID } = data;

  return asyncTaskID;
};

export const pollForConnectAccountProgress = async ({
  asyncTaskId,
}: {
  asyncTaskId: number;
}) => {
  const validate = (asyncTask: AsyncTask) =>
    asyncTask.status !== AsyncTaskStatus.COMPLETE &&
    asyncTask.status !== AsyncTaskStatus.ERROR &&
    asyncTask.status !== AsyncTaskStatus.WAITING_FOR_INPUT;

  const asyncTask = await poll(
    () => getAsyncTask({ asyncTaskId }),
    validate,
    5000
  );

  const taskData = asyncTask.taskData;

  const tokenAndInputRequired =
    asyncTask.status === AsyncTaskStatus.WAITING_FOR_INPUT
      ? 'requiresInput'
      : taskData?.token;

  return {
    token: tokenAndInputRequired,
    asyncTaskId,
    linkedinStatus: taskData?.linkedinStatus ?? 'ERROR_UNKNOWN',
  };
};

export const connectAccountWithSecretKey = async ({
  userId,
  email,
  password,
  secretKey,
}: {
  userId: number;
  email: string;
  password: string;
  secretKey: string;
}) => {
  const { data } = await axiosWithToken.post(`/connectAccount`, {
    userId,
    email,
    password,
    authenticationCode: secretKey,
  });

  const { asyncTaskID } = data;

  const validate = (asyncTask: AsyncTask) =>
    asyncTask.status !== AsyncTaskStatus.COMPLETE &&
    asyncTask.status !== AsyncTaskStatus.ERROR &&
    asyncTask.status !== AsyncTaskStatus.WAITING_FOR_INPUT;

  const asyncTask = await poll(
    () => getAsyncTask({ asyncTaskId: asyncTaskID }),
    validate,
    5000
  );

  const taskData = asyncTask.taskData;

  return {
    linkedinStatus: taskData?.linkedinStatus ?? LinkedinStatus.ERROR_UNKNOWN,
  };
};

export const queueImportContacts = async ({
  campaignId = null,
  importRules,
  name,
  projectUrl,
  type,
  userId,
}: {
  campaignId?: number | null;
  importRules?: DuplicateContactRules;
  name: string;
  projectUrl: string;
  type: ImportContactsTaskType;
  userId: number;
}) => {
  const { data } = await axiosWithToken.post(`/importContacts`, {
    campaignId,
    importRules,
    name,
    projectUrl,
    type,
    userId,
  });

  const { asyncTaskId } = data;

  return { asyncTaskId };
};

/** file://./../pages/api/contacts/filterDuplicates.ts */
export const getFilteredContacts = async ({
  campaignId,
  contacts,
  importRules,
}: {
  campaignId: number;
  contacts: NewContact[];
  importRules?: DuplicateContactRules;
}): Promise<(NewContact | Contact)[]> => {
  const response = await axiosWithToken.post(`/api/contacts/filterDuplicates`, {
    campaignId,
    contacts,
    importRules,
  });
  return response.data.contacts;
};

/** file://./../pages/api/campaigns/[campaignId]/contacts.ts */
export const downloadContacts = async ({
  campaignId,
  filters,
}: {
  campaignId: number;
  filters: CampaignContactStateFilter;
}) => {
  const response = await axiosWithToken.get(
    `/api/campaigns/${campaignId}/contacts`,
    {
      params: { filters, format: 'csv' },
    }
  );

  const asyncTaskId = response.data.asyncTaskId;

  const asyncTask = await pollForAsyncTaskComplete({ asyncTaskId });

  if (asyncTask.status === AsyncTaskStatus.ERROR) {
    throw new Error(asyncTask.taskData.errorMessage);
  }

  return asyncTask.taskData.presignedUrl;
};

/** file://./../pages/api/messages/[messageId]/[externalAttachmentId].ts */
export const downloadExternalAttachment = async ({
  messageId,
  externalAttachmentId,
}: {
  messageId: number;
  externalAttachmentId: string;
}) => {
  const response = await axiosWithToken.get(
    `/api/messages/${messageId}/attachments/${externalAttachmentId}`
  );
  return response.data.presignedUrl;
};

export const startCampaign = async ({ campaignId }: { campaignId: number }) => {
  await axiosWithToken.post(`/api/campaigns/${campaignId}/send`);
};

/*** Stripe and external API calls below ***/
export const goToBillingPortal = async ({
  stripeCustomerId,
}: {
  stripeCustomerId: string;
}) => {
  const { url } = await stripe.billingPortal.sessions.create({
    customer: stripeCustomerId,
    return_url: `${window.location.origin}/profile?tab=billing`,
  });

  window.open(url, '_blank');
};

export const updateInSlack = async ({
  channelCode,
  email,
  blocks,
  message,
}: {
  channelCode?: string;
  email?: string;
  blocks?: ChatPostMessageArguments['blocks'];
  message?: string;
}): Promise<void> => {
  await axiosWithToken.post(
    '/api/updateinslack',
    {
      channelCode,
      email,
      blocks,
      message,
    },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    }
  );
};

export const enrichContactsForCampaign = async ({
  campaignId,
  contactIds,
  enrichmentType,
  enrichmentLevel,
  overwriteExisting,
}: {
  campaignId: number;
  contactIds: number[];
  enrichmentType: EnrichmentType;
  enrichmentLevel: EnrichmentLevel;
  overwriteExisting: boolean;
}) => {
  const { data } = await axiosWithToken.post(
    `api/campaigns/${campaignId}/contacts/enrich`,
    {
      contactIds,
      enrichmentLevel,
      enrichmentType: enrichmentType || null,
      overwriteExisting,
    }
  );

  return data.asyncTaskId;
};

export const syncContactsWithIntegration = async ({
  campaignId,
  contactIds,
  integrationId,
}: {
  campaignId: number;
  contactIds: number[];
  integrationId: number;
}) => {
  const { data } = await axiosWithToken.post(`api/externalContacts/sync`, {
    campaignId,
    contactIds,
    integrationId,
  });

  const { asyncTaskId } = data;
  const asyncTask = await pollForAsyncTaskComplete({ asyncTaskId });

  if (asyncTask.status === AsyncTaskStatus.ERROR) {
    throw new Error(asyncTask.taskData.errorMessage);
  }
};

export const getExternalCampaigns = async ({
  integrationType,
}: {
  integrationType: IntegrationType;
}): Promise<ExternalJob[]> => {
  const response = await axiosWithToken.get(
    `api/externalCampaigns?integrationType=${integrationType}`
  );

  return response.data.externalCampaigns as ExternalJob[];
};

export const getUserEmailAccountAuthUrl = async ({
  email,
  userEmailAccountType,
}: {
  email: string;
  userEmailAccountType: string;
}) => {
  const response = await axiosWithToken.get(`api/userEmailAccounts/auth`, {
    params: { email, userEmailAccountType },
  });

  return response.data.authUrl;
};

export const createUserEmailAccount = async ({
  code,
  userEmailAccountType,
}: {
  code: string;
  userEmailAccountType: string;
}) => {
  const response = await axiosWithToken.post(`api/userEmailAccounts`, {
    code,
    userEmailAccountType,
  });

  return response.status === 200;
};

/** file://./../pages/api/personalizedMessages.ts */
export const getPersonalizedMessages = async ({
  filters,
}: {
  filters: PersonalizedMessageFilter;
}): Promise<PersonalizedMessage[]> => {
  const response = await axiosWithToken.get(`/api/personalizedMessages`, {
    params: { filters },
  });

  return response.data;
};

/** file://./../pages/api/campaigns/[campaignId]/contacts/personalize.ts */
export const createPersonalizedMessagesForContacts = async ({
  campaignId,
  contactIds,
}: {
  campaignId: number;
  contactIds: number[];
}): Promise<{
  numberPersonalizedPerContact: number;
  personalizedMessageIds: number[];
  userCreditsRemaining: number;
}> => {
  const response = await axiosWithToken.post(
    `/api/campaigns/${campaignId}/contacts/personalize`,
    { campaignId, contactIds }
  );

  const { asyncTaskId } = response.data;

  const asyncTask = await pollForAsyncTaskComplete({ asyncTaskId });

  if (asyncTask.status === AsyncTaskStatus.ERROR) {
    throw new Error(asyncTask.taskData.errorMessage);
  }

  const {
    numberPersonalizedPerContact,
    personalizedMessageIds,
    userCreditsRemaining,
  } = asyncTask.taskData;

  return {
    numberPersonalizedPerContact: numberPersonalizedPerContact || 0,
    personalizedMessageIds: personalizedMessageIds || [],
    userCreditsRemaining: userCreditsRemaining || 0,
  };
};

/** file://./../pages/api/personalizedMessages.ts */
export const updatePersonalizedMessages = async ({
  campaignId,
  contactIds,
  status,
}: {
  campaignId: number;
  contactIds: number[];
  status:
    | PersonalizedMessageStatus.ACCEPTED
    | PersonalizedMessageStatus.REJECTED;
}): Promise<void> => {
  const response = await axiosWithToken.patch(`/api/personalizedMessages`, {
    campaignId,
    contactIds,
    status,
  });

  return response.data;
};

/** file://./../pages/api/manualTasks.ts */
export const getManualTasks = async ({
  filters,
  paging,
}: {
  filters: ManualTaskFilter;
  paging?: Paging;
}): Promise<ManualTask[]> => {
  const response = await axiosWithToken.get(`/api/manualTasks`, {
    params: { filters, paging },
  });

  return response.data.manualTasks;
};

/** file://./../pages/api/event.ts */
export const sendEvent = async <T extends EventType>({
  eventType,
  metadata,
}: {
  eventType: EventType;
  metadata?: EventMetadata<T>;
}) => {
  await axiosWithToken.post(`/api/event`, {
    eventType,
    metadata,
  });
};
