import type { AsyncTask } from 'types/AsyncTask';
import type { LinkedinStatus } from 'types/Automation';
import type { EmailCredentials, UserEmailAccount } from './UserEmailAccount';

export enum UserStatus {
  NEW = 'NEW',
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  STANDARD = 'STANDARD',
}

interface LinkedinSession {
  cookies: object[];
  fId?: string;
  recruiterToken: string | null;
  token: string;
}

export type LinkedinCredentials = {
  authenticationCode?: string;
  email: string;
  password: string;
  newCredentials?: object;
} & LinkedinSession & {
    liveSession: LinkedinSession;
    // talinSession: LinkedinSession; TODO: wrap default session in a 'talinSession' object
  };

export interface SendingHours {
  startTime: string;
  endTime: string;
  timezone: string;
}

export interface ExtraData {
  disableCache?: boolean;
  emailOnly?: boolean;
  emailSignature?: string;
  isAtConnectionRequestLimit?: boolean;
  isBillingUser?: boolean;
  sendingHours?: SendingHours;
  shouldSendOnWeekends?: boolean;
  proratedUserLimitCreditCount?: number; // used to prorate credit counts when users upgrade their user licenses
}

// TODO: build a better system for ordering
type UserListOrderBy = 'name' | 'email' | 'role' | 'date_created';

export interface UserListQuery {
  customerId?: number;
  email?: string;
  excludeDisabled?: boolean;
  orderBy?: UserListOrderBy;
  orderDirection?: 'ASC' | 'DESC';
  roles?: UserRole[];
  statuses?: UserStatus[];
  userIds?: number[];
}

export interface User {
  userId: number;
  customerId: number;
  name: string;
  email: string;
  authId: string;
  status: UserStatus;
  isSysUser: boolean;
  role: UserRole;
  contactEnrichmentCreditCount: number;
  proxyId: number;
  userAgent: string;
  linkedinStatus: LinkedinStatus;
  linkedin2faType: string;
  linkedinCredentials: LinkedinCredentials;
  extraData?: ExtraData;
  dateCreated: Date;

  userEmailAccounts: UserEmailAccount[];
  phoneNumber?: string;

  emailStatus: string; // TODO: create enum
  emailCredentials: EmailCredentials;
  emailAccountId: string;
  sendingEmail: string; // TODO: feels like something that should live elsewhere
  isEmailVerified: boolean;

  inProgressAsyncTasks: AsyncTask[];

  hasSendNowEmailsInQueue: boolean;
  hasSendNowSmsMessagesInQueue: boolean;
}

export type NewUser = Pick<
  User,
  'name' | 'email' | 'status' | 'role' | 'linkedinStatus' | 'extraData'
> & {
  authId?: string | null;
  proxyId?: number | null;
  userAgent?: string | null;
  linkedinCredentials?: LinkedinCredentials | {};
  contactEnrichmentCreditCount?: number;
  invitedByUserId?: number;
};

export interface UserWithProxy extends User {
  proxyIp: string;
  proxyType: string;
  proxyZoneName: string;
}
