export enum LinkedinStatus {
  NEW = 'NEW',
  CONNECTED = 'CONNECTED',
  ERROR_INCORRECT_EMAIL = 'ERROR_INCORRECT_EMAIL',
  ERROR_INCORRECT_PASSWORD = 'ERROR_INCORRECT_PASSWORD',
  ERROR_INCORRECT_2FA_CODE = 'ERROR_INCORRECT_2FA_CODE',
  ERROR_2FA_CODE_NOT_SUBMITTED = 'ERROR_2FA_CODE_NOT_SUBMITTED',
  ERROR_2FA_NOT_ENABLED = 'ERROR_2FA_NOT_ENABLED',
  ERROR_REQUIRES_REAUTHENTICATION = 'ERROR_REQUIRES_REAUTHENTICATION',
  ERROR_UNKNOWN = 'ERROR_UNKNOWN',
  ACCOUNT_RESTRICTED = 'ACCOUNT_RESTRICTED',
}

export enum LinkedinSendError {
  ALREADY_CONNECTED = 'Already connected to contact',
  ALREADY_INVITED = 'Already invited contact',
  ALREADY_REPLIED = 'Contact already replied!',
  BAD_DATA = 'Bad data',
  COULD_NOT_FIND_CONTACT = 'Could not find contact',
  MESSAGE_NOT_SENT = 'Message not sent',
  NOT_CONNECTED = 'Contact not connected',
  NOT_LOGGED_IN = 'Not logged in',
  REQUIRES_EMAIL = 'Requires contact email to send connection request',
  SLOW_CONNECTION = 'Slow connection',
  TYPED_MESSAGE_DOES_NOT_MATCH = 'Typed message does not match',
  TYPING_MESSAGE_TO_WRONG_USER = 'Attempting to type message to wrong user',
  INVALID_URL = "Contact's uploaded LinkedIn URL is invalid",
  CONNECTION_NOTE_TOO_LONG = 'Connection request note for Linkedin is too long',
  CONNECTION_REQUEST_RECENTLY_WITHDRAWN = 'Recently withdrew connection request',
}

export enum LinkedinSyncError {
  COULD_NOT_FIND_CONTACT = 'Could not find contact',
  INCORRECT_CONVERSATION = 'Incorrect conversation',
}

export enum LinkedinAuthAttemptResult {
  SUCCESS = 'SUCCESS',
  RELOGIN = 'RELOGIN',
  FAILED = 'FAILED',
}
export interface LinkedinAuthAttempt {
  linkedinAuthAttemptId: number;
  userId: number;
  result: LinkedinAuthAttemptResult;
  worker: string;
  proxyIpAddress: string;
}
