import type { Paging, PagingWithCursor } from 'types/Paging';

export enum ContactActionType {
  INVITED = 'INVITED',
  CONNECTED = 'CONNECTED',
  MESSAGED = 'MESSAGED',
  MESSAGED_VIA_INMAIL = 'MESSAGED_VIA_INMAIL',
  REPLIED_VIA_INMAIL = 'REPLIED_VIA_INMAIL',
  EMAILED = 'EMAILED',
  SMS_SENT = 'SMS_SENT',
  REPLIED = 'REPLIED',
  REPLIED_VIA_EMAIL = 'REPLIED_VIA_EMAIL',
  REPLIED_VIA_SMS = 'REPLIED_VIA_SMS',
  OPENED_EMAIL = 'OPENED_EMAIL',
  RESUMED = 'RESUMED',
}

export interface ContactAction {
  contactActionId: number;
  customerId: number;
  userId: number;
  campaignId?: number;
  contactId: number;
  messageId?: number;
  sequenceStepId?: number;
  action: ContactActionType;
  actionData?: any;
  dateActionCompleted: Date;
}

export type NewContactAction = Omit<
  ContactAction,
  'contactActionId' | 'customerId' | 'dateActionCompleted'
> & {
  dateActionCompleted?: Date;
};

export type GetListContactAction = {
  contactActions: ContactAction[];
  paging?: Paging;
};

export type GetConversationContactAction = {
  contactActions: ContactAction[];
  paging: PagingWithCursor;
};

export type DisplayableContactAction = ContactAction & {
  campaignName: string;
  userName: string;
};
