import type {
  AdditionalInfo,
  ContactActionType,
  ContactEmail,
  ContactExtraData,
  ContactLocation,
  ContactPhoneNumber,
  ContactType,
} from 'types/Contact';
import type { Paging } from 'types/Paging';

export interface StateData {
  [key: string]: any;

  isConnected?: boolean;
  replyToMessageId?: number;
  replyToExternalMessageId?: string;
}

export interface CampaignContactState {
  campaignContactStateId: number;
  campaignId: number;
  contactId: number;
  customerId: number;
  dateCreated: Date;
  dateFirstCampaignAction?: Date;
  dateStepActionCompleted?: Date;
  dateStepStarted?: Date;
  dateUpdated: Date;
  sequenceId: number;
  sequenceStepId: number;
  stage: CampaignContactStage;
  stateData: StateData;
  status?: string; // TODO: remove this?
  stepStatus?: CampaignContactStateStepStatus;
}

export type NewCampaignContactState = Omit<
  CampaignContactState,
  | 'campaignContactStateId'
  | 'customerId'
  | 'dateCreated'
  | 'dateUpdated'
  | 'sequenceId'
  | 'stage'
>;

export interface CampaignContactStateWithContact extends CampaignContactState {
  companyName: string;
  dateCampaignCreated: Date;
  email: ContactEmail;
  type: ContactType;
  firstName: string;
  headline?: string;
  industry?: string | null;
  location?: ContactLocation;
  lastName: string;
  linkedinName: string;
  linkedinUrl: string;
  linkedinUrn: string;
  phoneNumber: ContactPhoneNumber;
  professionalEmail?: string;
  title?: string;
  externalContactId?: string;
  additionalInfo?: AdditionalInfo;
  extraData?: ContactExtraData | null;
}

export interface GetContactsByCampaignReturn {
  contacts: CampaignContactStateWithContact[];
  paging: Paging;
}

export interface CampaignContactStateFilter {
  contactIds?: number[];
  contactType?: ContactType;
  emailFilter?: ContactEmailFilter;
  personalizedFilter?: ContactPersonalizedFilter;
  hasExternalContactId?: boolean;
  searchFilter?: string;
  sequenceStepId?: number | null;
  stages?: CampaignContactStage[];
  status?: ContactStatusFilter;
}

export type ContactDropdownFilters = Pick<
  CampaignContactStateFilter,
  | 'contactIds'
  | 'emailFilter'
  | 'sequenceStepId'
  | 'stages'
  | 'status'
  | 'personalizedFilter'
>;

export enum ContactPersonalizedStatus {
  IS_PERSONALIZED = 'IS_PERSONALIZED',
  NOT_PERSONALIZED = 'NOT_PERSONALIZED',
}

export type ContactPersonalizedFilter =
  | ContactPersonalizedStatus.IS_PERSONALIZED
  | ContactPersonalizedStatus.NOT_PERSONALIZED;

export const NOT_CONNECTED = 'NOT_CONNECTED';

export type ContactStatusFilter =
  | ContactActionType.CONNECTED
  | ContactActionType.REPLIED
  | typeof NOT_CONNECTED;

export enum ContactEmailStatus {
  HAS_EMAIL = 'HAS_EMAIL',
  NO_EMAIL = 'NO_EMAIL',
}
export type ContactEmailFilter =
  | ContactEmailStatus.HAS_EMAIL
  | ContactEmailStatus.NO_EMAIL;

export interface ContactForSending extends CampaignContactStateWithContact {
  campaignType: string;
  title: string;
  sequenceId: number;
  action: string;
  actionData: {
    message?: string;
    subject?: string;
    body?: string;
  };
  stepLevel: number;
}

export enum CampaignContactStateStepStatus {
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  COMPLETE = 'COMPLETE',
}

export enum CampaignContactStage {
  NEW = 'NEW',
  INVITED = 'INVITED',
  CONNECTED = 'CONNECTED',
  MESSAGED = 'MESSAGED',
  MESSAGED_VIA_INMAIL = 'MESSAGED_VIA_INMAIL',
  REPLIED_VIA_INMAIL = 'REPLIED_VIA_INMAIL',
  EMAILED = 'EMAILED',
  SMS_SENT = 'SMS_SENT',
  IN_PROGRESS = 'IN_PROGRESS',
  REPLIED = 'REPLIED',
  REPLIED_VIA_EMAIL = 'REPLIED_VIA_EMAIL',
  REPLIED_VIA_LINKEDIN = 'REPLIED_VIA_LINKEDIN',
  REPLIED_VIA_SMS = 'REPLIED_VIA_SMS',
  ALREADY_CONNECTED = 'ALREADY_CONNECTED',
  ALREADY_INVITED = 'ALREADY_INVITED',
  NOT_CONNECTED = 'NOT_CONNECTED',
  UNRESPONSIVE = 'UNRESPONSIVE',
  INTERESTED = 'INTERESTED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  NO_EMAIL = 'NO_EMAIL',
  NO_PHONE_NUMBER = 'NO_PHONE_NUMBER',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  REQUIRES_EMAIL = 'REQUIRES_EMAIL',
  EMAIL_BOUNCED = 'EMAIL_BOUNCED',
  BAD_DATA = 'BAD_DATA',
  INVALID_URL = 'INVALID_URL',
  MESSAGE_TOO_LONG = 'MESSAGE_TOO_LONG',
  RECENTLY_WITHDRAWN = 'RECENTLY_WITHDRAWN',
  NEEDS_RECRUITER_PROFILE = 'NEEDS_RECRUITER_PROFILE',
  COMPLETED = 'COMPLETED',
}

export const CAMPAIGN_SKIP_STAGES = [
  CampaignContactStage.ALREADY_CONNECTED,
  CampaignContactStage.ALREADY_INVITED,
  CampaignContactStage.BAD_DATA,
  CampaignContactStage.EMAIL_BOUNCED,
  CampaignContactStage.INVALID_URL,
  CampaignContactStage.MESSAGE_TOO_LONG,
  CampaignContactStage.NEEDS_RECRUITER_PROFILE,
  CampaignContactStage.NO_EMAIL,
  CampaignContactStage.NOT_CONNECTED,
  CampaignContactStage.NOT_INTERESTED,
  CampaignContactStage.RECENTLY_WITHDRAWN,
  CampaignContactStage.REQUIRES_EMAIL,
  CampaignContactStage.UNRESPONSIVE,
  CampaignContactStage.NO_PHONE_NUMBER,
  CampaignContactStage.INVALID_PHONE_NUMBER,
];

export const readableSkipReasons: Record<string, string> = {
  [CampaignContactStage.ALREADY_CONNECTED]: 'Already connected',
  [CampaignContactStage.ALREADY_INVITED]: 'Already invited',
  [CampaignContactStage.BAD_DATA]: 'Bad data',
  [CampaignContactStage.EMAIL_BOUNCED]: 'Email bounced',
  [CampaignContactStage.INVALID_URL]: 'Invalid URL',
  [CampaignContactStage.MESSAGE_TOO_LONG]: 'Message too long',
  [CampaignContactStage.NEEDS_RECRUITER_PROFILE]: 'Needs recruiter profile',
  [CampaignContactStage.NO_EMAIL]: 'No email',
  [CampaignContactStage.NOT_CONNECTED]: 'Not connected',
  [CampaignContactStage.NOT_INTERESTED]: 'Not interested',
  [CampaignContactStage.RECENTLY_WITHDRAWN]: 'Recently withdrawn',
  [CampaignContactStage.REQUIRES_EMAIL]: 'Requires email',
  [CampaignContactStage.UNRESPONSIVE]: 'Unresponsive',
  [CampaignContactStage.NO_PHONE_NUMBER]: 'No phone number',
  [CampaignContactStage.INVALID_PHONE_NUMBER]: 'Invalid phone number',
};

export const CAMPAIGN_REPLIED_STAGES = [
  CampaignContactStage.REPLIED,
  CampaignContactStage.REPLIED_VIA_EMAIL,
  CampaignContactStage.REPLIED_VIA_LINKEDIN,
  CampaignContactStage.REPLIED_VIA_INMAIL,
  CampaignContactStage.REPLIED_VIA_SMS,
];

export const CAMPAIGN_EXCLUDE_STAGES = [
  ...CAMPAIGN_SKIP_STAGES,
  ...CAMPAIGN_REPLIED_STAGES,
  CampaignContactStage.INTERESTED,
];

export interface CampaignStepStats {
  count: number;
  sequenceStepId: number;
  stage: CampaignContactStage;
  stepStatus: CampaignContactStateStepStatus;
}

export interface CampaignStepContactActionStats {
  count: number;
  sequenceStepId: number;
  action: string;
}

export interface CampaignStepStatusStats {
  sequenceStepId: number;
  stepStatus: CampaignContactStateStepStatus;
  count: number;
}

export interface CampaignReplyStats {
  sequenceStepId: number;
  stage: CampaignContactStage;
  count: number;
}
