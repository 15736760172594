import type { Paging } from 'types/Paging';

export enum AsyncTaskStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  WAITING_FOR_INPUT = 'WAITING_FOR_INPUT',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  REQUEUING = 'REQUEUING',
  RETRYING = 'RETRYING',
}

export const COMPLETED_TASK_STATUSES = [
  AsyncTaskStatus.COMPLETE,
  AsyncTaskStatus.ERROR,
];

export enum AsyncTaskType {
  ACCEPT_PERSONALIZED_MESSAGE = 'ACCEPT_PERSONALIZED_MESSAGE',
  CONNECT_ACCOUNT = 'CONNECT_ACCOUNT',
  CONTACT_ENRICHMENT = 'CONTACT_ENRICHMENT', // populates email and phone for multiple contacts
  INDIVIDUAL_CONTACT_ENRICHMENT = 'INDIVIDUAL_CONTACT_ENRICHMENT',
  CONTACT_PROFILE_ENRICHMENT = 'CONTACT_PROFILE_ENRICHMENT', // Populates additional info data
  DOWNLOAD_CONTACTS = 'DOWNLOAD_CONTACTS',
  EXPORT_CONTACTS = 'EXPORT_CONTACTS',
  EXTERNAL_CONTACT_SYNC = 'EXTERNAL_CONTACT_SYNC',
  LINKEDIN_GET_DATA = 'LINKEDIN_GET_DATA',
  LINKEDIN_SCHEDULED_GET_DATA = 'LINKEDIN_SCHEDULED_GET_DATA',
  IMPORT_CONTACTS = 'IMPORT_CONTACTS',
  LINKEDIN_SCHEDULED_SEND = 'LINKEDIN_SCHEDULED_SEND',
  LINKEDIN_SEND = 'LINKEDIN_SEND',
  PERSONALIZED_MESSAGE = 'PERSONALIZED_MESSAGE',
  SEND_EMAIL = 'SEND_EMAIL',
  SEND_SMS = 'SEND_SMS',
}

export interface AsyncTaskProgress {
  message: string;
  percent: number;
}

export interface AsyncTask {
  customerId?: number;
  userId?: number;
  asyncTaskId?: number;
  async_task_id: number;
  status: AsyncTaskStatus;
  type: AsyncTaskType;
  taskData: any;
  dateCompleted?: Date;
  dateUpdated?: Date;
}

export interface AsyncTaskFilter {
  campaignIds?: number[];
  asyncTaskIds?: number[];
  excludeAsyncTaskIds?: number[];
  lookBackHours?: number;
  types?: AsyncTaskType[];
  statuses?: AsyncTaskStatus[];
}

export interface GetAsyncTaskListReturn {
  asyncTasks: AsyncTask[];
  paging: Paging;
}
