import type { CampaignContactStage } from 'types/Campaign';
import type { Paging } from 'types/Paging';
import type { SequenceWithSteps } from 'types/Sequence';

export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  IDLE = 'IDLE',
  SENDING = 'SENDING',
}

export enum CampaignType {
  EMAIL = 'EMAIL',
  LINKEDIN_CONNECTION_REQUEST = 'LINKEDIN_CONNECTION_REQUEST',
  LINKEDIN_INMAIL = 'LINKEDIN_INMAIL',
  LINKEDIN_MESSAGE = 'LINKEDIN_MESSAGE',
  MULTICHANNEL = 'MULTICHANNEL',
  SMS = 'SMS',
}

export enum CampaignTargetType {
  CANDIDATE = 'CANDIDATE',
  PROSPECT = 'PROSPECT',
}

export interface SendingHours {
  startTime: string;
  endTime: string;
  timezone: string;
}

export interface ExternalCampaign {
  externalCampaignId: string;
  integrationId: number;
}

export interface ExtraData {
  externalCampaign?: ExternalCampaign | null;
  sendingHours?: SendingHours;
  imports?: {
    projectUrl: string;
    dateLastImported: Date | string;
  }[];
}

export enum CampaignScopes {
  READ = 'read',
  WRITE = 'write',
}
export interface CampaignFilter {
  ownerId?: number;
  campaignIds?: number[];
  searchFilter?: string;
  statuses?: CampaignStatus[];
  scopes?: CampaignScopes[];
}

export interface GetCampaignListReturn {
  campaigns: Campaign[] | CampaignWithSequence[];
  paging: Paging;
}

export interface Campaign {
  campaignId: number;
  customerId: number;
  ownerId: number;
  name: string;
  targetType: CampaignTargetType;
  type: CampaignType;
  sequenceId: number;
  status: CampaignStatus;
  linkedinDailyLimit: number;
  extraData?: ExtraData;
  dateCreated: Date;
  dateUpdated: Date;

  ownerEmail?: string;
  ownerName: string;
  totalCount: number;
  invitedCount?: number;
  repliedCount?: number;

  totalStats?: CampaignTotalStats;
  hasConnectedData?: boolean;
}

export type NewCampaign = Pick<
  Campaign,
  | 'ownerId'
  | 'name'
  | 'targetType'
  | 'type'
  | 'sequenceId'
  | 'status'
  | 'linkedinDailyLimit'
  | 'extraData'
>;

export interface CampaignWithSequence extends Campaign {
  steps: SequenceWithSteps['steps'];
  totalStats?: CampaignTotalStats;
}

export interface CampaignTotalStats {
  total: number;
  inProgress: number;
  completed: number;
  skipped: number;
  skippedBreakdown: {
    [key in CampaignContactStage]?: number;
  };
  sentAtFirstSteps: number;
  connected: number;
  replied: number;
}
